export default {
  "/": {
    ru: {
      title: "Элайнеры TranspaDent – капы для выравнивания зубов",
      desc:
        "Элайнеры TranspaDent — это отличная альтернатива брекет системам. Наша продукция является лучшей в Украине по соотношению цена-качество!",
      keys:
        "элайнеры, элайнер, выравнивание зубов элайнерами, капы для выравнивания зубов, уход за элайнерами, заказать элайнеры, лечение элайнерами, капы для исправления прикуса",
      url: "",
      img: require("./assets/home_page.jpg"),
    },
  },
  "/for-doctors/": {
    ru: {
      title: "Страница для врачей. Заказ и изготовление элайнеров",
      desc:
        "Закажите капы для выравнивания зубов TranspaDent через наших представителей, телефоны которых указаны на сайте.",
      keys:
        "лечение зубочелюстных аномалий, изготовления элайнеров, система выравнивания зубов, прозрачные капы для выравнивания зубов, виды прикуса, система элайнеров, аномалии прикуса",
      url: "for-doctors/",
      img: require("./assets/for_doctors.jpg"),
    },
  },
  "/classification-of-anomalies/": {
    ru: {
      title: "Классификация аномалий. Выравнивание зубов.",
      desc:
        "В этом разделе представлены 3D модели зубов с разными типами нарушения прикуса, которые гарантировано устраняются при помощи элайнеров TranspaDent.",
      keys: "выравнивание зубов, выравнивание прикуса, нарушения прикуса",
      url: "classification-of-anomalies/",
      img: require("./assets/classification_of_anomalies.jpg"),
    },
  },
};
