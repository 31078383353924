import React, { Suspense } from "react";
import propTypes from "prop-types";
import { Route, Redirect } from "react-router";
import FullPageLoader from "./components/FullPageLoader";
import { ROUTES } from "./constants/routes";

export const OpenRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Suspense fallback={<FullPageLoader />}>
        <Component {...props} />
      </Suspense>
    )}
  />
);

export const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth === true ? (
        <Suspense fallback={<FullPageLoader />}>
          <Component {...props} />
        </Suspense>
      ) : (
        <Redirect to={ROUTES.home} />
      )
    }
  />
);

OpenRoute.propTypes = {
  component: propTypes.object.isRequired,
};

PrivateRoute.propTypes = {
  auth: propTypes.bool.isRequired,
  component: propTypes.object.isRequired,
};
