import React from "react";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import store from "./store";

import App from "./App";
import "./i18n";

import * as serviceWorker from "./registerServiceWorker"; // dont use service worker for production

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement,
  );
} else {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement,
  );
}

serviceWorker.unregister();
