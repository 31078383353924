import React, { lazy } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import InfoPopup from "./components/InfoPopup";
import { ROUTES } from "./constants/routes";
import { OpenRoute } from "./Routes";
import "./App.scss";
import MetaTags from "./SEO/MetaTags";

const HomeLayout = lazy(() => import(/* webpackChunkName: "HomeLayout" */ "./layouts/HomeLayout"));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffa000",
    },
    secondary: {
      main: "#00acc1",
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 21,
        "& .MuiButton-label": {
          textAlign: "center",
          color: "#fff",
        },
      },
    },
  },
});

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <MetaTags baseUrlsProp={{ ru: "https://transpadent.org/" }} title={"TranspaDent"} />
        <InfoPopup />
        <Switch>
          <OpenRoute path={ROUTES.home} component={HomeLayout} />
          <Redirect from="*" to={ROUTES.home} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
