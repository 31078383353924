import { OBTAIN_PROFILE_DATA, START_GET_PROFILE, OBTAIN_PROFILE_ERROR } from "../actions/profileAction";

const initialState = {
  loading: false,
  errors: [],
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case START_GET_PROFILE:
      return {
        ...state,
        loading: true,
        errors: [],
      };
    case OBTAIN_PROFILE_DATA:
      return {
        ...state,
        loading: false,
        ...action.payload,
        errors: [],
      };
    case OBTAIN_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
